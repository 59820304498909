<template>
  <template v-if="value">
    {{ Math.round(parseFloat(value)) }}
  </template>

  <template v-else>
    -
  </template>
</template>

<script>
export default {
  name: 'DatatablesNumberWithoutDecimals',

  props: ['value']
}
</script>
