<template>

  <div class="bg-concrete rounded-md shadow-sm">
    <div class="flex flex-col">
      <v-table v-if="data.length > 0" class="simple-table" :class="{ 'highlight-last': highlightLast }">
        <template #header>
          <th
            scope="col"
            class="px-2 py-3 text-left text-xs font-bold text-blumine uppercase tracking-wider"
            v-for="(header, hindex) in data[0]" :key="hindex"
          >
            {{ header.title }}
          </th>
        </template>

        <v-table-row v-for="(item, index) in data" :key="index" class=" hover:bg-gray-50">
          <td class="px-2 py-4 whitespace-nowrap text-sm text-big-stone" v-for="(column, cindex) in item" :key="cindex">
            <div v-if="column.component === 'money-field' && typeof column.value === 'number'">
              {{ money.format(column.value, 0) }}
            </div>
            <div v-else>{{ column.value }}</div>
          </td>
        </v-table-row>
      </v-table>
      
      <div v-else class="p-4 font-semibold">
        No Data
      </div>
    </div>
  </div>

</template>

<script>
import VTable from '@/components/Table.vue'
// import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
// import VTableCol from '@/components/TableCol.vue'

import { markRaw } from 'vue'
import money from '@/helpers/money.js'


export default {
  components: {
    VTable,
    // VTableHeader,
    VTableRow,
    // VTableCol,
  },

  props: ['data', 'highlightLast'],

  setup() {
    return {
      money: markRaw(money),
    }
  }
}
</script>

<style>
  .highlight-last tbody > tr:last-child > td {
    @apply bg-big-stone bg-opacity-80 text-white font-bold;
  }
  .simple-table td:first-child, .simple-table th:first-child {
    @apply pl-3 font-bold;
  }
</style>
