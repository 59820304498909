<template>
  <layout>
    <template #secondarySidebar>
      <reports-nav />
    </template>

    <reports-toolbar
      v-model:filters="state.filters"
      title="Cashout Report"
      :machines="state.machines"
      @update-filters="updateFilters"
    />

    <div
      v-if="state.isLoading"
      class="flex flex-col items-center p-4 text-center"
    >
      <div class="flex items-center">
        <icon-loading class="w-6 h-6 text-blumine mr-3" /> Loading data...
      </div>
    </div>
    <div v-else-if="state.apiError">
      <alert-response-warning :error="state.apiError" />
    </div>
    <div
      v-else-if="columns.length > 0"
      class="mt-6"
    >
      <datatables-table
        :columns="columns"
        :show-mode-toggler="false"
        :show-column-visibility="false"
        default-mode="swipe"
        :sort-by="{
          key: 'location_name',
          direction: 'ASC'
        }"
        :show-footer="true"
      >
        <template #footer>
          <div class="flex flex-col md:flex-row md:justify-between md:items-start mt-4">
            <action-buttons
              :data="dataForExport"
              :filter="exportFilterDesc"
              filename="CashoutReport"
              class="py-0.5"
            />
          </div>
        </template>
      </datatables-table>
    </div>
    <div
      v-else
      class="mt-6 text-center"
    >
      No Data. Please update filters
    </div>
  </layout>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import money from '@/helpers/money.js'
import Layout from '@/layouts/Default.vue'
import ReportsNav from '@/components/reports/Nav.vue'
import ReportsToolbar from '@/components/reports/Toolbar.vue'
import { IconLoading } from '@/components/icons'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import SimpleTable from '@/components/SimpleTable.vue'
import ExportActionButtons from '@/components/datatables/ActionButtons.vue'
import DatatablesTable from '@/components/datatables/Table.vue'
import { sum, totalExportRow } from '@/helpers/datatables.js'
import DateTimeField from '@/components/datatables/Fields/DateTimeField.vue'
import ActionButtons from '@/components/datatables/ActionButtons.vue'
import MoneyField from '@/components/datatables/Fields/MoneyField.vue'
import MoneyShortField from '@/components/datatables/Fields/MoneyShortField.vue'
import NumberWithoutDecimals from '@/components/datatables/Fields/NumberWithoutDecimals.vue'

export default {
  name: 'ReportsCashout',

  components: {
    Layout,
    ReportsNav,
    ReportsToolbar,
    IconLoading,
    SimpleTable,
    ExportActionButtons,
    AlertResponseWarning,
    DatatablesTable,
    ActionButtons,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      isLoading: true,
      apiError: false,
      machines: [],
      tableData: [],
      filters: {
        type: 'all',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        machines: [],
        range: {
          from: date.now().subtract(1, 'day').subtract(1, 'week').format('YYYY-MM-DD'),
          to: date.now().format('YYYY-MM-DD'),
        }
      },
    })

    const machineIdsString = computed(() => state.filters.machines.join(','))

    const hydrateFiltersFromUrl = () => {
      state.filters.machines = route.query.machines ? route.query.machines.split(',').map((m) => parseInt(m)) : []
      route.query.from ? state.filters.range.from = route.query.from : null
      route.query.to ? state.filters.range.to = route.query.to : null
      route.query.timezone ? state.filters.timezone = route.query.timezone : null
      route.query.type ? state.filters.type = route.query.type : null
      state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))
    }

    const updateFilters = () => {
      router.replace({
        query: {
          from: state.filters.range.from,
          to: state.filters.range.to,
          timezone: state.filters.timezone,
          machines: machineIdsString.value,
          type: state.filters.type,
        }
      })
    }

    const getMachines = async () => {
      hydrateFiltersFromUrl()

      const { data: machines } = await http.get('user/machines')
      const allowedLocations = machines.allowed_locations.filter(m => !m.is_technician)
      state.machines = state.selectedMachines = allowedLocations

      if (allowedLocations.length === 0) {
        state.isLoading = false
        state.apiError = 'You either do not have any machines assigned or you are now allowed to view reports on any of the assigned machines.'
      }
      else if (state.filters.machines.length === 0) {
        state.filters.machines = allowedLocations.map((machine) => machine.location_id)
        updateFilters()
      }
      else {
        getData()
      }
      state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))
    }

    const getData = async () => {
      state.isLoading = true
      state.apiError = false

      try {
        const { data } = await http.get('report/cashout', {
          params: {
            start: state.filters.range.from,
            end: state.filters.range.to,
            // timezone: state.filters.timezone,
            location_ids: machineIdsString.value,
            type: state.filters.type,
          }
        })
        state.tableData = data.data
      } catch (e) {
        state.apiError = e
      }

      state.isLoading = false
    }

    const columns = computed(() => {
      return state.tableData.map((item) => ({
        columns: [
          { title: 'Cashout Date & Time', key: 'cashout_date', value: item.cashout_date, footer: 'Total', component: DateTimeField },
          { title: 'Machine', key: 'location_name', value: item.location_name, className: 'font-bold text-left text-base', priority: 1 },
          { title: 'Cash Sales Value', key: 'cash_sales_value', value: item.cash_sales_value, className: 'text-right', footer: sum, component: MoneyField },
          { title: 'Vend Count', key: 'vends_count', value: item.vends_count, className: 'text-right', footer: sum, component: NumberWithoutDecimals },
          { title: 'Vend Value', key: 'vends_value', value: item.vends_value, className: 'text-right', footer: sum, component: MoneyField },
          { title: 'Bill Value', key: 'bill_value', value: item.bill_value, className: 'text-right', footer: sum, component: MoneyShortField },
          { title: 'Coin Value', key: 'coin_value', value: item.coin_value, className: 'text-right', footer: sum, component: MoneyField },
          { title: 'CC Value', key: 'credit_card_value', value: item.credit_card_value, className: 'text-right', footer: sum, component: MoneyField },
          { title: 'Token Count', key: 'token_count', value: item.token_count, className: 'text-right', footer: sum, component: NumberWithoutDecimals },
          { title: 'User', key: 'user', value: item.user },
        ]
      }))
    })

    const dataForExport = computed(() => {
      const tableData = columns.value.map((row) => row.columns)

      if (state.tableData.length > 1) {
        const totalRow = totalExportRow(columns.value)

        if (totalRow) {
          tableData.push(totalRow)
        }
      }

      return tableData
    })

    watch(
      () => route.query,
      () => {
        if (route.name === 'ReportsCashout') {
          // Set timeout to get correct query params from URL
          setTimeout(() => {
            hydrateFiltersFromUrl()
            getData()
          }, 100)
        }
      }
    )

    onBeforeRouteUpdate(() => {
      setTimeout(() => {
        if (Object.keys(route.query).length === 0 && route.query.constructor === Object) {
          updateFilters()
        }
      }, 100)
    })

    getMachines()

    const exportFilterDesc = computed(() => {
      let value = ''

      if (state.filters.type) {
        value += `Type: ${state.filters.type}\n`
      }

      if (state.filters.range) {
        value += `Date Range: ${state.filters.range.from} - ${state.filters.range.to}`
      }

      if (value.length > 0) {
        value = 'Active Filter\n' + value
      }

      return value
    })

    return {
      state,
      money,
      updateFilters,
      exportFilterDesc,
      columns,
      dataForExport
    }
  }
}
</script>
