<template>
  {{ dateTime }}
</template>

<script>
import { computed } from 'vue'
import date from '@/helpers/date.js'

export default {
  name: 'DatatablesFieldsDateTimeField',

  props: ['value'],

  setup(props) {
    return {
      dateTime: computed(() => date.logFormat(date.utc(props.value.substring(0, 23)).unix()))
    }
  }
}
</script>
